import React from 'react'

function HAgencies() {
  return (
    <>
    <section id="hagency">
        <div className='container-fluid'>
          
            <div className="hagency-km-header">
            <div class="red-circle-ag"></div>                <h4>Agencies at Katsam Media</h4>
                <span className='strip2'></span>
                <p>At <span>Katsam Media</span>, we embody the commitment of a full-service agency by <span>establishing specialized agencies</span> for diverse marketing and branding segments. Our determination ensures <span>tailored solutions</span> for every client need. With a <span>forward-thinking approach</span>, we continuously evolve, anticipating market demands to <span>create new agencies</span>, ensuring our <span>relevance and effectiveness</span> in the ever-changing landscape of media and branding.</p>
            </div>
         
        </div>

    </section>
    </>
  )
}

export default HAgencies