import React from 'react'
import Hero from './Hero.js'
import Cards from './Cards.js'
import Cta from '../Include/Cta.js'
import Footer from '../Include/Footer.js'
import HAgencies from '../Home/HAgencies.js'
import Company from '../Home/Company.js'
import Media from '../Home/Media.js'
import Insight from '../Home/Insight.js'
import Insight2 from '../Home/Insight2.js'
import { Helmet } from 'react-helmet';




export default function Home() {
  return (
  <>
        <Helmet>
    <title> Top Marketing Agency in India | Full-Service Experts for Your Brand.</title>
        <meta name="description" content="Experience growth like never before with our full-service marketing agency. From strategy development to implementation, we're here to fuel your success." />
      </Helmet>


     <Hero/>
     <Insight/>
    {/* <Cards/> */}
    <HAgencies/>
    <Company/>
    <Insight2/>
    <Cta/>
    <Footer/>
  </>
  )
}
