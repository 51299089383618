import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Insightimg from "../assests/Insight1.png";
import caseimg from '../assests/3.png'
import caseimg2 from '../assests/4.png'
import {Link} from "react-router-dom";


const Insight = () => {
  const dynamicData = [
    {
      id: 1,
      src: caseimg,
      description: "Starry Secrets: A Gen-Z Star-Studded chat show!",
      desc:"Keen to know what goes on and off-screen in the life of your favourite star? Watch the Show!"
    },
    {
      id: 2,
      src: caseimg2,
      description: "Lionesses Roar in Kabaddi: Unleashing Fierce Spirit",
      desc:"Wanna know what goes behind that it takes a village? Walk through this written documentary.  "

    }
    
    // Add more items as needed
  ];

  const options = {
    items: 2,
    loop: false,
    nav: false,
    margin: 50,
    dots:true,
    autoplay: true,
    autoplayTimeout: 30000000000000000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1.1,
    margin: 10,

      },
      600: {
        items: 1.2,
    margin: 10,

      },
      1000: {
        items: 2,
      },
    },
  };

  return (
    <section className="sec-menu" id="katsam-exp">
      <div class="menu d-none d-sm-none d-md-block">
        <div class="menu__item">
          <div class="marquee">
            <div class="marquee__inner">
              <span className="d-block d-sm-none d-md-block">Katsam Media</span>
              <span className="d-block d-sm-none d-md-block">Katsam Media</span>
              <span className="d-block d-sm-none d-md-block">Katsam Media</span>
              <span>Katsam Media</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="headcontainer">
          <div class="red-circle"></div>
          <h5 className="headtitle" >
           Katsam Experience
            <div className="strip"></div>
          </h5>
        </div>

        <div className="col-12 ">
          <div className="silder2">
            <OwlCarousel className="owl-theme" {...options}>
              {dynamicData.map((item) => (
                <>
                   <Link to="/casestudies">
                  <div class="item1">
                    <a
                      className="hovercard"
                      href="#0"
                      aria-labelledby="person1"
                    ></a>
                    <img src={item.src} alt="Marketing Agency, full service marketing agency"  />
                    <div class="item__overlay1 title-box">
                      <div className="row">
                        <div className="col-md-9 col-lg-10 col-10">
                          <h4 id="person1" aria-hidden="true">
                            {item.description}
                          </h4>
                        </div>
                        <div className="col-lg-2 col-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="32"
                            viewBox="0 0 36 32"
                            fill="none"
                          >
                            <path
                              d="M10.5125 28.3965L34.6592 6.99979"
                              stroke="white"
                            />
                            <path
                              d="M7.29599 24.5082L27.9418 6.42788L16.1893 5.32506"
                              stroke="white"
                              stroke-width="3"
                            />
                          </svg>
                        </div>
                      </div>

                      <div class="item__body">
                        <p>{item.desc}</p>
                      </div>
                    </div>
                  </div>
                  </Link>
                </>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Insight;
