import React, { useState, useEffect } from 'react';
import "./App.css";
import Pre from "./Pre.gif";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import Blog from "../src/Blog/Blog"
import Contact from './Contacts/Contact';
import Privacy from '../src/Privacy/Privacy';
import CaseStudiesdeatils from './CaseStudies/CaseStudiesdetails';
import Blogdetail from './Blog/Blogdetail';
import CaseStudies from './CaseStudies/CaseStudies';
import Career from './Career';
import About from './About/About';


function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay or API call
    const fetchData = async () => {
      // Your loading logic here
      // For example, you can use a setTimeout to simulate a delay
      setTimeout(() => {
        setIsLoading(false);
      }, 2500);
    };

    fetchData();
  }, []);


  return (
    <>


<div >
      {isLoading ? (
  <div id="preloader-container">
  <div id="preloader">
    <img src={Pre} alt='' className='img-fluid'/>
  </div>
</div>
    ) : (
        // Your content when loading is complete
        <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/full-service-advertising-agency" element={<About />} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/contact-us" element={<Contact/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/casestudies" element={<CaseStudies/>} />
          <Route path="/Career-Growth-Opportunities" element={<Career/>} />
          <Route path="/blogdetails" element={<Blogdetail/>} />
          <Route path="/casestudiesdetails" element={<CaseStudiesdeatils/>} />

          

        </Routes>
      </BrowserRouter>
      )}
</div>




     
    </>
  );
}

export default App;

